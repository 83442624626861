//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Profile',
  data() {
    return {
      tab: '',
      category: '',
    };
  },
  computed: {
    signedIn() {
      return this.$store.getters.signedIn;
    },
    // props de user-card
    getUser() {
      return this.$store.getters.currentUser;
    },
    // props de pin-profile
    getPinUser() {
      return this.$store.getters.myPin;
    },
    getPinStatus() {
      return this.$store.getters.pinCompleted; // verifica se o usuário possui um pin
    },
    // show infos from store
    myEventsSize() {
      return this.$store.state.myEvents.length;
    },
    getMyEvents() {
      const showMyEvents = this.$store.getters.myEvents;
      return showMyEvents;
    },
  },
  methods: {
    addCategorie() {
      this.$store.dispatch('addCategorie', {
        name: this.category,
      })
        .then(() => {
          console.log('compTest : add categorie');
        });
    },
    logout() {
      this.$store.dispatch('destroyToken');
      console.log('dashboard : try logout');
    },
    showEditInfo() {
      this.opemEditInfo = !this.opemEditInfo;
    },
  },
};
