import { render, staticRenderFns } from "./Dashboard.vue?vue&type=template&id=3bed42ee&scoped=true&"
import script from "./Dashboard.vue?vue&type=script&lang=js&"
export * from "./Dashboard.vue?vue&type=script&lang=js&"
import style0 from "./Dashboard.vue?vue&type=style&index=0&id=3bed42ee&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bed42ee",
  null
  
)

export default component.exports
import {QChip,QAvatar,QBtn,QTabs,QTab,QSeparator,QTabPanels,QTabPanel,QInput} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QChip,QAvatar,QBtn,QTabs,QTab,QSeparator,QTabPanels,QTabPanel,QInput})
